import { computed } from 'vue'

import Stage from '@/models/Stage'
import { Image } from '@/models/Image'
import Story from '@/models/Story'
import { useStore } from '@/store'
import { formatTime } from '@/utils'
import ArtistDetail from '@/modals/ArtistDetail.vue'

export default class Artist extends Story {
  public get stage(): Stage {
    const { getStoryByUuid } = useStore()
    return getStoryByUuid(this.content.stage)
  }

  public get day() {
    return this.content?.day.toLowerCase()
  }

  public get image(): Image {
    return this.content?.image ? this.content.image : null
  }

  public get thumb(): string {
    return this.image ? `${this.image.filename}/m/300x300/smart` : ''
  }

  public get picture(): string {
    return this.image ? `${this.image.filename}/m/1200x1200/smart` : ''
  }

  public get perex() {
    return this.content.perex
  }

  public get blocks() {
    return this.content.content
  }

  public get duration() {
    return this.content.duration
  }

  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram']
      .map((key) => ({
        icon: key,
        link: this.content[key] || null,
      }))
      .filter((i) => i.link)
  }

  public get timeStart() {
    return formatTime(this.content.time_start)
  }

  public get timeEnd() {
    return formatTime(this.content.time_start).plus({ minutes: this.duration })
  }

  public get timeFormatted() {
    return `${this.timeStart.toFormat('dd.MM. HH:mm')} - ${this.timeEnd.toFormat('HH:mm')}`
  }

  public get timeFormattedShort() {
    return `${this.timeStart.toFormat('HH:mm')} - ${this.timeEnd.toFormat('HH:mm')}`
  }

  public get isLiked() {
    const store = useStore()
    return computed(() => store.likes.find((l) => l.uuid === this.uuid))
  }

  public get view() {
    return ArtistDetail
  }
}
