import { initializeApp } from 'firebase/app'
import { collection, doc, getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBxT7AQ1MhGdu7oye7xIoI9zyS1b97fqa8',
  authDomain: 'sharpe-festival.firebaseapp.com',
  projectId: 'sharpe-festival',
  storageBucket: 'sharpe-festival.appspot.com',
  messagingSenderId: '787681599915',
  appId: '1:787681599915:web:2db419cbf7d73b5dd132ee',
}

initializeApp(firebaseConfig)
export const db = getFirestore()
export const blurhashCollection = collection(db, 'blurhash')
export const allDoc = doc(db, 'blurhash', 'all')
export const playlistsDoc = (id: string) => doc(db, 'playlist', id)
