import App from './App.vue'
import router from './router'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia } from 'pinia'
import { StoryblokVue, apiPlugin } from '@storyblok/vue'
import { createApp } from 'vue'
// eslint-disable-next-line import/order
import {
  IonApp,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  IonicVue,
} from '@ionic/vue'

import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/fonts.css'

import { MotionPlugin } from '@vueuse/motion'
import { GesturePlugin } from '@vueuse/gesture'
import VueVirtualScroller from 'vue-virtual-scroller'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import Youtube from '@/components/storyblok/Youtube.vue'
import NewsFeed from '@/components/storyblok/NewsFeed.vue'
import Wysiwyg from '@/components/storyblok/Wysiwyg.vue'
import Swiper from '@/components/storyblok/Swiper.vue'
import Banner from '@/components/storyblok/Banner.vue'
import CountDown from '@/components/storyblok/CountDown.vue'
import Pages from '@/components/storyblok/Pages.vue'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(pinia)
  .use(router)

app.use(MotionPlugin)
app.use(GesturePlugin)

app.use(VueVirtualScroller)

app.component('IonPage', IonPage)
app.component('IonHeader', IonHeader)
app.component('IonToolbar', IonToolbar)
app.component('IonTitle', IonTitle)
app.component('IonContent', IonContent)
app.component('IonButton', IonButton)
app.component('IonApp', IonApp)
app.component('IonRouterOutlet', IonRouterOutlet)
app.component('IonSplitPane', IonSplitPane)
app.component('IonMenu', IonMenu)
app.component('IonFab', IonFab)
app.component('IonFabButton', IonFabButton)
app.component('IonIcon', IonIcon)
app.component('IonTabBar', IonTabBar)
app.component('IonTabButton', IonTabButton)
app.component('IonTabs', IonTabs)
app.component('IonMenuButton', IonMenuButton)
app.component('IonLabel', IonLabel)
app.component('IonItem', IonItem)
app.component('IonList', IonList)
app.component('IonAvatar', IonAvatar)
app.component('IonBadge', IonBadge)
app.component('IonChip', IonChip)
app.component('IonButtons', IonButtons)

app.use(StoryblokVue, {
  accessToken: process.env.VUE_APP_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
})

app.component('Youtube', Youtube)
app.component('Wysiwyg', Wysiwyg)
app.component('NewsFeed', NewsFeed)
app.component('Swiper', Swiper)
app.component('Banner', Banner)
app.component('CountDown', CountDown)
app.component('Pages', Pages)

router.isReady().then(() => {
  app.mount('#app')
})
