import TabsPage from '../views/TabsPage.vue'

import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { SplashScreen } from '@capacitor/splash-screen'
import { Device } from '@capacitor/device'

import { useStore } from '@/store'
import Homepage from '@/views/Homepage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/homepage',
  },
  {
    path: '/artists/:id',
    component: () => import('@/modals/ArtistDetail.vue'),
  },
  {
    path: '/speakers/:id',
    component: () => import('@/modals/SpeakerDetail.vue'),
  },
  {
    path: '/news/:id',
    component: () => import('@/modals/NewsDetail.vue'),
  },
  {
    path: '/pages/:id',
    component: () => import('@/modals/PageDetail.vue'),
  },
  {
    path: '/pages',
    component: () => import('@/modals/PageDetail.vue'),
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/homepage',
      },
      {
        path: 'homepage',
        component: Homepage,
      },
      {
        path: 'artists',
        component: () => import('@/views/Artists.vue'),
      },
      {
        path: 'speakers',
        component: () => import('@/views/Speakers.vue'),
      },
      {
        path: 'schedule',
        component: () => import('@/views/Schedule.vue'),
      },
      {
        path: 'map',
        component: () => import('@/views/Map.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(async () => {
  const store = useStore()
  await store.loadStories()

  if (!store.platform) {
    const info = await Device.getInfo()
    store.platform = info.platform
  }

  if (store.splashHidden || store.platform === 'web') return

  await new Promise((resolve) => setTimeout(resolve, 2000))
  await SplashScreen.hide({
    fadeOutDuration: 2000,
  })
  store.splashHidden = true
})

export default router
