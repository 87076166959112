import Story from '@/models/Story'

export default class Settings extends Story {
  public get favorite(): boolean {
    return this.content.favorite
  }

  public get news(): boolean {
    return this.content.news
  }

  public get artists(): boolean {
    return this.content.artists
  }

  public get schedule(): boolean {
    return this.content.schedule
  }

  public get map(): boolean {
    return this.content.map
  }

  public get menu(): boolean {
    return this.content.menu
  }

  public get web(): string {
    return this.content.web
  }

  public get maps(): Record<string, string> {
    return {
      conference: this.content.mapConference?.filename,
      festival: this.content.mapFestival?.filename,
    }
  }

  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram']
      .map((key) => ({
        icon: key,
        link: this.content[key] || null,
      }))
      .filter((i) => i.link)
  }
}
