<template>
  <ion-content class="more">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title size="large"> More </ion-title>
      </ion-toolbar>
    </ion-header>

    <Pages />
  </ion-content>
</template>

<script>

</script>

<style lang="scss" scoped>
.more {
  --background: var(--ion-color-light);
}
</style>
