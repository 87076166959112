import { InjectionKey, Ref, computed, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import { modalController, onIonViewDidEnter, onIonViewDidLeave, useIonRouter } from '@ionic/vue'

import { useStore } from '@/store'

export const useSection = () => {
  const store = useStore()
  const route = useRoute()
  const ionRouter = useIonRouter()
  const section = computed({
    get: () => store.section,
    set: (value: 'festival' | 'conference') => (store.section = value),
  })

  const toggleSection = async () => {
    section.value = section.value === 'festival' ? 'conference' : 'festival'

    if (route.path === '/tabs/artists') await ionRouter.replace('/tabs/speakers')

    if (route.path === '/tabs/speakers') await ionRouter.replace('/tabs/artists')
  }

  return {
    section,
    toggleSection,
  }
}

export const ModalWrapper: InjectionKey<Ref<HTMLElement>> = Symbol('ModalWrapper')
export const useModal = async (story: any, wrapper: any) => {
  const store = useStore()
  const modal = await modalController.create({
    component: toRaw(story.view),
    componentProps: { data: story },
    showBackdrop: false,
    canDismiss: true,
    mode: 'ios',
    presentingElement: wrapper.value.$el,
  })

  store.modalOpened++
  await modal.present()
  await modal.onWillDismiss()
  store.modalOpened--
}

export const disableSideMenuSwipe = () => {
  const store = useStore()
  onIonViewDidEnter(() => {
    store.disableMenu = true
  })

  onIonViewDidLeave(() => {
    store.disableMenu = false
  })
}
