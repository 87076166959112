import { computed } from 'vue'

import { useStore } from '@/store'
import Page from '@/models/Page'
import Stage from '@/models/Stage'
import Artist from '@/models/Artist'
import Speaker from '@/models/Speaker'
import Timeslot from '@/models/Timeslot'
import News from '@/models/News'

export const useContent = () => {
  const store = useStore()

  const pages = computed<Page[]>(() => {
    return store.stories.filter((story: any) => story.type === 'page').filter((s) => s.title) || []
  })

  const festivalStages = computed<Stage[]>(() => {
    return (
      store.stories.filter((story: any) => story.type === 'festival').filter((s) => s.title) || []
    )
  })

  const conferenceStages = computed<Stage[]>(() => {
    return (
      store.stories.filter((story: any) => story.type === 'conference').filter((s) => s.title) || []
    )
  })

  const artists = computed<Artist[]>(() => {
    return (
      store.stories.filter((story: any) => story.type === 'artist').filter((s) => s.title) || []
    )
  })

  const speakers = computed<Speaker[]>(() => {
    return (
      store.stories.filter((story: any) => story.type === 'speaker').filter((s) => s.title) || []
    )
  })

  const timeslots = computed<Timeslot[]>(() => {
    return (
      store.stories.filter((story: any) => story.type === 'timeslot').filter((s) => s.title) || []
    )
  })

  const news = computed<News[]>(() => {
    return store.stories.filter((story: any) => story.type === 'news').filter((s) => s.title) || []
  })

  const getStoryByUuid = (uuid: string) => {
    return store.stories.find((story: any) => story.uuid === uuid) || null
  }

  return {
    news,
    pages,
    festivalStages,
    conferenceStages,
    artists,
    speakers,
    timeslots,
    getStoryByUuid,
  }
}
